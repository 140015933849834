export const Providers = {
  FACEBOOK: 'facebook',
  YELP: 'yelp',
  GMB: 'gmb',
  YOTPO: 'yotpo',
  MANUAL: 'manual'
};

export const ProviderConnectDataEvents = {
  facebook: 'REVIEWS_FB_CONNECT_CLICKED',
  yelp: 'REVIEWS_YELP_CONNECT_CLICKED',
  gmb: 'REVIEWS_GMB_CONNECT_CLICKED',
  yotpo: 'REVIEWS_YOTPO_CONNECT_CLICKED'
};

export const ProviderIcon = {
  facebook: 'facebook2',
  yelp: 'yelpLogoNegative',
  gmb: 'gmb',
  yotpo: 'yotpo'
};

export const ProviderAlternateIcon = {
  facebook: 'facebook',
  yelp: 'yelpLogoNegative',
  gmb: 'gmb',
  yotpo: 'yotpo'
};

export const ProviderSource = {
  facebook: 'fb',
  yelp: 'yelp',
  gmb: 'gmb',
  yotpo: 'yotpo'
};

export const RecommendationIcons = {
  positive: 'fbRecommends',
  negative: 'fbDoesNotRecommend'
};

export const RecommendationStrings = {
  positive: 'recommends',
  negative: 'doesNotRecommend'
};

export const ProviderName = {
  facebook: 'Facebook',
  gmb: 'Google My Business',
  yelp: 'Yelp',
  yotpo: 'Yotpo'
};

export const ProviderPhotoUrls = {
  facebook: 'https://graph.facebook.com/v3.0/{id}/picture?height=100&width=100'
};

export const ProviderUrls = {
  base: {
    facebook: 'https://facebook.com',
    gmb: 'https://maps.google.com',
    yelp: 'https://www.yelp.com/biz'
  },
  photo: {
    facebook: 'https://graph.facebook.com/v3.0/{id}/picture'
  }
};

export const STATES = {
  NOT_SETUP: 'NOT_SETUP',
  LOADING: 'LOADING',
  HAS_REVIEWS: 'HAS_REVIEWS',
  FAILED: 'FAILED',
  NO_REVIEWS: 'NO_REVIEWS',
  CONNECTED: 'CONNECTED',
  NOT_CONNECTED: 'NOT_CONNECTED'
};

export const ENV_CONFIG = {
  reseller: {
    local: 'dev-secureserver.net',
    development: 'dev-secureserver.net',
    test: 'test-secureserver.net',
    production: 'secureserver.net'
  },
  godaddy: {
    local: 'dev-godaddy.com',
    development: 'dev-godaddy.com',
    test: 'test-godaddy.com',
    production: 'godaddy.com'
  }
};

export const ArrowType = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
};

export const ConnectURLTemplate = {
  facebook: 'https://dashboard.{rootDomain}/fbPageCreate/{accountId}?home={home}',
  gmb: 'https://dashboard.{rootDomain}/account/{accountId}/gmb',
  yelp: 'https://dashboard.{rootDomain}/account/{accountId}/yelp',
  yotpo: 'https://{websiteId}.onlinestore.{rootDomain}/admin/general_settings/edit#reviews'
};

export const UpgradeURLTemplate = {
  yotpo: 'https://launch.{rootDomain}/?account_uid={accountId}&path=plans'
};

export const getApiUrl = (env, websiteId, provider) => {
  if (provider === Providers.YOTPO) {
    return `https://proxy.apps-api.instantpage.${ENV_CONFIG.reseller[env]}/v1/proxy/yotpo?websiteId=${websiteId}`;
  }

  return `https://proxy.apps-api.instantpage.${ENV_CONFIG.reseller[env]}/v1/proxy/stats?id=${websiteId}&source=${ProviderSource[provider]}`;
};

export const getConnectStatusUrl = (env, accountId, isReseller) => {
  // important for this API call since we need to send cookies in the request
  const rootDomain = isReseller ? ENV_CONFIG.reseller[env] : ENV_CONFIG.godaddy[env];
  return `https://proxy.apps-api.instantpage.${rootDomain}/v1/proxy/social?accountId=${accountId}`;
};

export const getRootDomain = (env, isReseller) => {
  const config = isReseller ? ENV_CONFIG.reseller : ENV_CONFIG.godaddy;
  return config[env];
};

export const MAX_REVIEWS_COUNT = 9;
