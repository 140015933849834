const REVIEWS_PATH = '/reviews';

export const WEBSITE_PROPERTIES = {
  GMB_STATUS_KEY: 'gmbStatusResponse',
  API_RESPONSE_KEY: 'apiRequestResponseData'
};

export const GMB_STATUS = {
  PUBLISHED: 'PUBLISHED',
  DUPLICATE: 'DUPLICATE',
  PENDING: 'PENDING',
  UNKNOWN: 'UNKNOWN'
};

export const SOURCE_TYPES = {
  DYNAMIC: 'dynamic',
  STATIC: 'static'
};

export const MANUAL_REVIEWS = {
  ROUTE: `${REVIEWS_PATH}/$`,
  PATH: REVIEWS_PATH,
  PHOTO: `${REVIEWS_PATH}/$/photo`
};
