import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UX2 } from '@wsb/guac-widget-core';
import { Providers, ProviderIcon } from '../constants';
import DataAid from '../constants/dataAids';

const paleWhite = {
  color: 'section'
};

// The header link should not open a new page
const internalLinkProviders = [Providers.YOTPO];
const providersHidingReviewBar = [Providers.MANUAL];

const staticStyles = {
  topBar: {
    'display': 'flex',
    'alignItems': 'center',
    'alignContent': 'center',
    'justifyContent': 'center',
    'cursor': 'pointer',
    'marginBottom': 'large',
    '@xs-only': {
      boxShadow: 'none',
      justifyContent: 'center'
    },
    '@sm': {
      justifyContent: 'center'
    }
  },
  overallRating: {
    marginLeft: 'small'
  },
  ratingStar: {
    'backgroundColor': 'transparent',
    'marginHorizontal': 'medium',
    '@md': {
      marginHorizontal: '0px'
    }
  },
  overallRatingContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'small'
  },
  overallReviews: {
    lineHeight: 'normal',
    marginTop: 'xxsmall'
  },
  recommended: {
    fontWeight: 'bold',
    lineHeight: 'normal'
  },
  mobileTopBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0'
  },
  businessName: {
    marginBottom: 'xxsmall',
    lineHeight: 'normal'
  },
  pageLink: {
    'display': 'block',
    'color': 'section',
    ':hover': paleWhite,
    ':visited': paleWhite,
    'textTransform': 'none'
  },
  pageLinkContainer: {
    padding: '0',
    marginBottom: 'medium',
    textAlign: 'center'
  }
};

class ReviewBar extends Component {
  static propTypes = {
    provider: PropTypes.string,
    overallRating: PropTypes.number,
    totalReviews: PropTypes.number,
    isMobile: PropTypes.bool,
    pageLink: PropTypes.string,
    staticContent: PropTypes.object.isRequired,
    hasBgImage: PropTypes.bool,
    businessName: PropTypes.string,
    category: PropTypes.string,
    section: PropTypes.string,
    positiveRecommendations: PropTypes.number,
    negativeRecommendations: PropTypes.number
  };

  static defaultProps = {
    provider: Providers.FACEBOOK,
    overallRating: 0.0,
    totalReviews: 0
  };

  generateReviewBarStyles() {
    const { provider } = this.props;
    return {
      linkProps: {
        tag: 'a',
        target: internalLinkProviders.includes(provider) ? '_self' : '_blank',
        rel: internalLinkProviders.includes(provider) ? 'alternate' : 'noopener',
        style: { display: 'block' }
      }
    };
  }

  getBusinessNameBlock() {
    const { businessName } = this.props;
    return (
      <UX2.Element.Text.Major
        data-aid={ DataAid.REVIEWS_BUSINESS_NAME_RENDERED }
        style={ staticStyles.businessName }
        children={ businessName }
      />
    );
  }

  getRecommendationPercentage() {
    const { positiveRecommendations, negativeRecommendations } = this.props;
    return positiveRecommendations > 0
      ? Math.ceil(
        (positiveRecommendations * 100) / (positiveRecommendations + negativeRecommendations)
      )
      : 0;
  }

  getMobileRatingBar() {
    const {
      provider,
      overallRating,
      positiveRecommendations,
      staticContent,
      negativeRecommendations
    } = this.props;
    if (provider === Providers.FACEBOOK && positiveRecommendations > 0) {
      const percent = this.getRecommendationPercentage();
      const totalReviewsCount = positiveRecommendations + negativeRecommendations;
      return (
        <UX2.Element.Block
          data-aid={ DataAid.RECOMMENDATION_RENDERED }
          style={{ ...staticStyles.topBar, ...{ flexDirection: 'column' } }}
        >
          <UX2.Element.Text.Major
            data-aid={ DataAid.RECOMMENDATION_TEXT_RENDERED }
            style={ staticStyles.recommended }
          >
            { staticContent.percentRecommend.replace('{percent}', `${percent}%`) }
          </UX2.Element.Text.Major>
          <UX2.Element.Text
            data-aid={ DataAid.RECOMMENDATION_DESC_RENDERED }
            style={{ ...staticStyles.overallReviews, textAlign: 'center', marginBottom: 'small' }}
          >
            { totalReviewsCount === 1
              ? staticContent.basedOnOne
              : staticContent.basedOn.replace('{reviewCount}', totalReviewsCount) }
          </UX2.Element.Text>
        </UX2.Element.Block>
      );
    }

    return (
      <UX2.Element.Block style={ staticStyles.topBar }>
        <UX2.Element.Heading.Major style={ staticStyles.overallRating }>
          { overallRating.toFixed(1) }
        </UX2.Element.Heading.Major>
        <UX2.Component.Rating
          data-aid={ DataAid.RATING_RENDERED }
          rating={ overallRating }
          style={ staticStyles.ratingStar }
        />
      </UX2.Element.Block>
    );
  }

  getDesktopRatingBar() {
    const {
      provider,
      positiveRecommendations,
      negativeRecommendations,
      overallRating,
      totalReviews,
      staticContent
    } = this.props;
    let totalReviewsText = totalReviews === 1 ? staticContent.review : staticContent.reviews;
    if (provider === Providers.YOTPO) {
      totalReviewsText =
        totalReviews === 1 ? staticContent.productReview : staticContent.productReviews;
    }

    if (provider === Providers.FACEBOOK && positiveRecommendations > 0) {
      const percent = this.getRecommendationPercentage();
      const totalReviewsCount = positiveRecommendations + negativeRecommendations;
      return (
        <UX2.Element.Block
          data-aid={ DataAid.RECOMMENDATION_RENDERED }
          style={ staticStyles.overallRatingContainer }
        >
          { this.getBusinessNameBlock() }
          <UX2.Element.Text.Major
            data-aid={ DataAid.RECOMMENDATION_TEXT_RENDERED }
            style={ staticStyles.recommended }
          >
            { staticContent.percentRecommend.replace('{percent}', `${percent}%`) }
          </UX2.Element.Text.Major>
          <UX2.Element.Text
            data-aid={ DataAid.RECOMMENDATION_DESC_RENDERED }
            style={ staticStyles.overallReviews }
          >
            { totalReviewsCount === 1
              ? staticContent.basedOnOne
              : staticContent.basedOn.replace('{reviewCount}', totalReviewsCount) }
          </UX2.Element.Text>
        </UX2.Element.Block>
      );
    }

    return (
      <UX2.Element.Block style={ staticStyles.overallRatingContainer }>
        { this.getBusinessNameBlock() }
        { overallRating > 0 && (
          <UX2.Component.Rating
            data-aid={ DataAid.RATING_RENDERED }
            rating={ overallRating }
            style={ staticStyles.ratingStar }
          />
        ) }
        <UX2.Element.Text style={ staticStyles.overallReviews }>
          { totalReviewsText.replace('{totalReviews}', totalReviews) }
        </UX2.Element.Text>
      </UX2.Element.Block>
    );
  }

  render() {
    const {
      provider,
      overallRating,
      totalReviews,
      businessName,
      positiveRecommendations,
      negativeRecommendations,
      pageLink,
      staticContent,
      isMobile,
      hasBgImage,
      category,
      section
    } = this.props;

    if (providersHidingReviewBar.includes(provider)) {
      return null;
    }

    const styles = this.generateReviewBarStyles();
    styles.linkProps.href = pageLink;
    const allReviewText =
      provider === Providers.YOTPO
        ? staticContent.viewAllProductReviews
        : staticContent.viewAllReviews;

    const totalReviewsComputed =
      provider === Providers.FACEBOOK && positiveRecommendations > 0
        ? positiveRecommendations + negativeRecommendations
        : totalReviews;

    return (
      <UX2.Element.Container
        category={ hasBgImage ? 'accent' : category }
        section={ hasBgImage ? 'overlay' : section }
      >
        { !isMobile && (
          <UX2.Element.Element { ...styles.linkProps } style={{ 'text-decoration-line': 'none' }}>
            <UX2.Element.Block style={ staticStyles.topBar }>
              <UX2.Element.Block style={{ marginTop: 'xxsmall' }}>
                <UX2.Element.Icon icon={ ProviderIcon[provider] } size='xlarge' />
              </UX2.Element.Block>
              { provider !== Providers.FACEBOOK && overallRating > 0 && (
                <UX2.Element.Heading.Major style={ staticStyles.overallRating }>
                  { overallRating.toFixed(1) }
                </UX2.Element.Heading.Major>
              ) }
              <UX2.Element.Block style={ staticStyles.overallRatingContainer }>
                { this.getDesktopRatingBar() }
              </UX2.Element.Block>
            </UX2.Element.Block>
          </UX2.Element.Element>
        ) }
        { isMobile && (
          <UX2.Element.Container style={ staticStyles.mobileTopBarContainer }>
            <UX2.Element.Text.Major
              data-aid={ DataAid.REVIEWS_BUSINESS_NAME_RENDERED }
              style={{
                marginBottom: 'xxsmall',
                textAlign: 'center'
              }}
              children={ businessName }
            />
            { this.getMobileRatingBar() }
            <UX2.Element.Container style={ staticStyles.pageLinkContainer }>
              <UX2.Element.Element
                tag='a'
                target='_blank'
                href={ pageLink }
                style={ staticStyles.pageLink }
              >
                { allReviewText.replace(
                  '{totalReviews}',
                  totalReviewsComputed === 1 ? '' : totalReviewsComputed
                ) }
              </UX2.Element.Element>
            </UX2.Element.Container>
          </UX2.Element.Container>
        ) }
      </UX2.Element.Container>
    );
  }
}

export default ReviewBar;
