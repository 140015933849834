import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { constants as coreConstants, UX2 } from '@wsb/guac-widget-core';
import {
  ProviderPhotoUrls,
  Providers,
  RecommendationIcons,
  RecommendationStrings,
  ProviderAlternateIcon
} from '../constants';
import Truncate from 'react-truncate';
import DataAid from '../constants/dataAids';
import { formatDate } from '../utils';

const relativeLinkProviders = [Providers.YOTPO];

const truncateSizes = {
  mobile: 240,
  desktop: 240
};
const styles = {
  common: {
    display: 'flex',
    flexGrow: '1',
    flexShrink: '0',
    flexBasis: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  photoContainer: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    backgroundColor: 'rgba(240,240,240,0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  photoStyles: {
    width: '100px',
    height: '100px',
    display: 'inline-block'
  },
  firstInitial: {
    color: '#CBCBCB',
    lineHeight: '1',
    padding: 'medium',
    fontSize: 'xxxlarge'
  },
  reviewBodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingHorizontal: 'medium',
    paddingVertical: 'medium',
    textTransform: 'none',
    flexGrow: 2,
    width: '100%'
  },
  reviewTitle: {
    marginBottom: 'small',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 'normal'
  },
  sharedReviewContent: {
    lineHeight: '1.3',
    minHeight: '42px'
  },
  fullReviewOverlay: {
    overflowY: 'auto',
    height: '100%'
  },
  reviewerContainer: {
    paddingHorizontal: 'medium',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ['@xs-only']: {
      marginTop: '0'
    }
  },
  reviewer: {
    paddingHorizontal: 'small',
    fontSize: 'xsmall',
    lineHeight: 'normal'
  },
  recommendationContainer: {
    paddingTop: 'medium',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icons: {
    facebook: {
      color: '#4267B2',
      height: '25px',
      width: '25px'
    },
    recommendation: {
      width: '20px'
    }
  },
  fullReview: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '100%',
    opacity: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'action',
    color: 'action',
    padding: 'small',
    transition: 'all 0.3s ease',
    ['@md']: {
      padding: 'medium'
    }
  },
  fullReviewHover: {
    overflow: 'hidden',
    ['@md']: {
      ':hover': {
        '.fullreview': {
          opacity: 1,
          top: 0,
          zIndex: 1
        }
      }
    }
  },
  fullReviewClose: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    cursor: 'pointer'
  },
  fullReviewOverlayShowOnMobile: {
    opacity: 1,
    top: 0,
    zIndex: 1
  },
  linkContainer: {
    marginTop: 'small'
  },
  ratingStars: {
    marginTop: 'medium',
    ['@xs-only']: {
      marginTop: 'small'
    }
  }
};
// Limit this feature to providers that have deep link
const readMoreProviders = [Providers.FACEBOOK, Providers.YELP, Providers.YOTPO, Providers.GMB];
// Shows "Read more" link all the time if it exists
const readMoreStaticProviders = [Providers.MANUAL];
const goToProviders = [Providers.YOTPO];
const allowedRecommendations = ['positive', 'negative'];
const DESKTOP_MIN_WIDTH = 300;

class ReviewCard extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    provider: PropTypes.string.isRequired,
    link: PropTypes.string,
    isMobile: PropTypes.bool,
    nextPageHandler: PropTypes.func,
    previousPageHandler: PropTypes.func,
    desktopMobilePreview: PropTypes.bool,
    staticContent: PropTypes.object,
    renderMode: PropTypes.string.isRequired,
    market: PropTypes.string,
    sourceType: PropTypes.string,
    cardHeight: PropTypes.string
  };

  constructor() {
    super(...arguments);
    this.state = {
      truncated: false,
      imageFailed: false,
      showFullReviewOverlay: false,
      showExternalLink: false
    };

    this.handleTruncate = this.handleTruncate.bind(this);
    this.toggleFullReviewOverlay = this.toggleFullReviewOverlay.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
    this.getTotalReviewLines = this.getTotalReviewLines.bind(this);
    this.cardComponent = React.createRef();
  }

  handleTruncate(truncated) {
    const { provider } = this.props;
    if (this.state.truncated !== truncated) {
      this.setState({
        truncated,
        showExternalLink: readMoreProviders.includes(provider)
      });
    }
  }

  toggleFullReviewOverlay() {
    this.setState({ showFullReviewOverlay: !this.state.showFullReviewOverlay });
  }

  handleImageError() {
    this.setState({ imageFailed: true });
  }

  getReviewerPhoto(data, provider) {
    const { sourceType } = this.props;
    const { imageFailed } = this.state;
    let imageData, image;
    if (data.photo) {
      image = data.photo;
    } else {
      imageData = data.imageData || {};
      image = imageData.image || '';
    }
    // guard against incorrect photo url
    const hasValidPhoto =
      image && (image.toLowerCase().startsWith('http') || image.startsWith('//'));
    let photoSrc = hasValidPhoto ? image : null;
    const externalProvider = sourceType === 'dynamic';
    if (data.metadata && data.metadata.reviewerId && ProviderPhotoUrls[provider]) {
      photoSrc = ProviderPhotoUrls[provider].replace('{id}', data.metadata.reviewerId);
    }

    const firstInitial = data.name ? data.name.charAt(0).toUpperCase() : null;
    const PHOTO_WIDTH = 100;
    const PHOTO_HEIGHT = 100;
    const imageProps = externalProvider
      ? {
        src: photoSrc
      }
      : {
        imageData: {
          ...imageData,
          outputWidth: PHOTO_WIDTH,
          outputHeight: PHOTO_HEIGHT
        }
      };

    return (
      <UX2.Element.Block style={ styles.photoContainer }>
        { photoSrc && !imageFailed && (
          <UX2.Element.Image.Thumbnail
            tag='img'
            data-aid={ DataAid.REVIEWER_PHOTO_RENDERED }
            style={ styles.photoStyles }
            onError={ this.handleImageError }
            { ...imageProps }
          />
        ) }
        { (!photoSrc || imageFailed) && firstInitial && (
          <UX2.Element.Heading
            data-aid={ DataAid.REVIEWER_INITIAL_RENDERED }
            children={ firstInitial }
            style={ styles.firstInitial }
          />
        ) }
      </UX2.Element.Block>
    );
  }

  getRatingBlock() {
    const { provider, data, staticContent, renderMode } = this.props;

    if (
      provider === Providers.FACEBOOK &&
      data.recommendation &&
      allowedRecommendations.includes(data.recommendation)
    ) {
      return (
        <UX2.Element.Block
          data-aid={ DataAid.RECOMMENDATION_RENDERED }
          style={ styles.recommendationContainer }
        >
          <UX2.Element.Icon
            data-aid={ DataAid.RECOMMENDATION_ICON_RENDERED }
            icon={ RecommendationIcons[data.recommendation] }
            style={ styles.icons.recommendation }
          />
          <UX2.Element.Text
            data-aid={ DataAid.RECOMMENDATION_TEXT_RENDERED }
            style={{ paddingLeft: 'small' }}
          >
            { staticContent[RecommendationStrings[data.recommendation]] }
          </UX2.Element.Text>
        </UX2.Element.Block>
      );
    }

    // keeping the elements in the dom so that the alignments are correct in various use cases
    const visibility =
      provider === Providers.MANUAL &&
      renderMode !== coreConstants.renderModes.EDIT &&
      data.rating === '0'
        ? 'hidden'
        : 'visible';

    return (
      <UX2.Component.Rating
        data-aid={ DataAid.RATING_RENDERED }
        style={{ ...styles.ratingStars, visibility }}
        rating={ data.rating }
      />
    );
  }

  getTruncatedLine(isMobile, data, addQuote = false) {
    return (
      <Truncate
        lines={ 1 }
        ellipsis={ addQuote ? '..."' : '...' }
        trimWhitespace={ true }
        width={ isMobile ? truncateSizes.mobile : truncateSizes.desktop }
      >
        { `${data}` }
      </Truncate>
    );
  }

  sanitizeLink(link, provider) {
    if (!relativeLinkProviders.includes(provider) && link && !link.startsWith('http')) {
      return `//${link}`;
    }

    return link;
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    const { data: prevData } = prevProps;
    if (
      data.photo !== prevData.photo ||
      get(data, 'imageData.image') !== get(prevData, 'imageData.image')
    ) {
      this.setState({ imageFailed: false });
    }
  }

  componentDidMount() {
    const card = this.cardComponent.current && ReactDOM.findDOMNode(this.cardComponent.current); // eslint-disable-line react/no-find-dom-node
    const currentCardWidth = (card && card.offsetWidth) || 0;
    this.setState({ currentCardWidth });
  }

  getTotalReviewLines(showStaticLink) {
    const { isMobile, provider } = this.props;
    const { currentCardWidth } = this.state;

    if (isMobile || (!isMobile && showStaticLink)) {
      return 3;
    }
    const desktopLines = provider === Providers.MANUAL ? 4 : 3;
    return currentCardWidth && currentCardWidth <= DESKTOP_MIN_WIDTH ? 2 : desktopLines;
  }

  render() {
    const {
      data,
      provider,
      isMobile,
      desktopMobilePreview,
      link,
      staticContent,
      market,
      cardHeight
    } = this.props;
    const { truncated, showExternalLink, showFullReviewOverlay } = this.state;
    const showStaticLink = readMoreStaticProviders.includes(provider) && get(data, 'link', false);
    const shouldRenderFullReviewOverlay = truncated && !(showStaticLink || showExternalLink);
    const hasProductLink =
      goToProviders.includes(provider) && !!(data && data.metadata && data.metadata.product);
    const reviewOpacity = goToProviders.includes(provider) ? '0.6' : '1';
    const reviewTitle = (data && data.metadata && data.metadata.title) || '';
    const totalReviewLines = this.getTotalReviewLines(showStaticLink);
    const isManual = provider === Providers.MANUAL;

    const cardStyles = {
      card: {
        textAlign: 'center',
        borderWidth: '0px',
        textShadow: 'none',
        position: 'relative',
        boxShadow: '2px 6px 30px 0 rgba(0,0,0,0.18)',
        paddingVertical: 'large',
        height: cardHeight,
        width: '290px',
        ['@xs-only']: {
          paddingVertical: 'medium'
        }
      }
    };

    const hoverStyles = shouldRenderFullReviewOverlay ? styles.fullReviewHover : {};
    const fullReviewMobileStyles = showFullReviewOverlay
      ? styles.fullReviewOverlayShowOnMobile
      : {};

    if (isMobile) {
      if (!desktopMobilePreview) {
        cardStyles.card.minWidth = '290px';
        cardStyles.card.width = 'auto';
      }
    }

    let date = formatDate(data.date, market);
    if (!date && isManual && data.date) {
      // For manual events, fall back to the customer text if the date can't be formatted
      date = data.date;
    }

    return (
      <UX2.Element.Block>
        <UX2.Group.Card
          ref={ this.cardComponent }
          style={{ ...styles.common, ...cardStyles.card, ...hoverStyles }}
        >
          { shouldRenderFullReviewOverlay && (
            <UX2.Element.Block
              style={{ ...styles.fullReview, ...fullReviewMobileStyles }}
              className='fullreview'
              category='accent'
              section='overlay'
            >
              { isMobile && (
                <UX2.Element.Icon
                  icon='close'
                  style={ styles.fullReviewClose }
                  onClick={ this.toggleFullReviewOverlay }
                />
              ) }
              <UX2.Element.Text
                children={ data.body }
                style={{
                  color: 'action',
                  ...styles.sharedReviewContent,
                  ...styles.fullReviewOverlay
                }}
              />
            </UX2.Element.Block>
          ) }
          <UX2.Element.Container style={ styles.topContainer }>
            { this.getReviewerPhoto(data, provider) }
            { this.getRatingBlock() }
          </UX2.Element.Container>
          <UX2.Element.Block style={ styles.reviewBodyContainer }>
            { hasProductLink && (
              <UX2.Element.Link
                data-aid={ DataAid.PRODUCT_LINK_RENDERED }
                target='_self'
                href={ this.sanitizeLink(data.link || link, provider) }
              >
                { this.getTruncatedLine(isMobile, data.metadata.product) }
              </UX2.Element.Link>
            ) }
            { reviewTitle && (
              <UX2.Element.Heading
                data-aid={ DataAid.REVIEW_TITLE_RENDERED }
                style={ styles.reviewTitle }
              >
                { reviewTitle }
              </UX2.Element.Heading>
            ) }
            { data.body && (
              <UX2.Element.Text
                style={{ ...styles.sharedReviewContent, opacity: reviewOpacity }}
                featured
              >
                <Truncate
                  data-aid={ DataAid.USER_REVIEW_RENDERED }
                  lines={ totalReviewLines }
                  ellipsis='..."'
                  trimWhitespace={ true }
                  width={ isMobile ? truncateSizes.mobile : truncateSizes.desktop }
                  onTruncate={ this.handleTruncate }
                >
                  { `"${data.body}"` }
                </Truncate>
              </UX2.Element.Text>
            ) }
            { !data.body && (
              <UX2.Element.Details data-aid={ DataAid.STATIC_REVIEW_RENDERED }>
                { staticContent.noWrittenReviews }
              </UX2.Element.Details>
            ) }
            <UX2.Element.Block style={{ ...styles.linkContainer }}>
              { (showStaticLink || showExternalLink) && (
                <UX2.Element.MoreLink.Forward
                  data-aid={ DataAid.READ_MORE_RENDERED }
                  target={ hasProductLink ? '_self' : '_blank' }
                  href={ this.sanitizeLink(data.link || link, provider) }
                >
                  { staticContent.readFullReview }
                </UX2.Element.MoreLink.Forward>
              ) }
              { isMobile && truncated && !(showStaticLink || showExternalLink) && (
                <UX2.Element.Link
                  tag='span'
                  onClick={ this.toggleFullReviewOverlay }
                  children={ staticContent.viewMore || 'View More' }
                />
              ) }
            </UX2.Element.Block>
          </UX2.Element.Block>
          <UX2.Element.Block style={ styles.reviewerContainer }>
            <UX2.Element.Icon
              icon={ ProviderAlternateIcon[provider] }
              size='medium'
              style={ styles.icons[provider] }
            />
            <UX2.Element.Details.Minor
              data-aid={ DataAid.REVIEWER_INFO_RENDERED }
              tag='p'
              style={ styles.reviewer }
            >
              <strong>
                { data.name }
                { data.name && date ? ' - ' : '' }
              </strong>
              { date }
            </UX2.Element.Details.Minor>
          </UX2.Element.Block>
        </UX2.Group.Card>
      </UX2.Element.Block>
    );
  }
}

export default ReviewCard;
