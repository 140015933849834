/* eslint-disable id-length */
import keyMirror from 'keymirror';

export default keyMirror({
  REVIEWS_SECTION_TITLE_RENDERED: null,
  REVIEWS_NO_DATA_RENDERED: null,
  REVIEWS_NOT_CONNECTED_MSG: null,
  PENDING_VALIDATION_HEADER: null,
  PENDING_VALIDATION_CONTENT: null,
  WAITING_FOR_DATA_HEADER: null,
  WAITING_FOR_DATA_CONTENT: null,
  SECTION_BACKGROUND: null,
  REVIEWS_BUSINESS_NAME_RENDERED: null,
  REVIEWS_CONNECT_BUTTON: null,
  USER_REVIEW_RENDERED: null,
  STATIC_REVIEW_RENDERED: null,
  READ_MORE_RENDERED: null,
  PRODUCT_LINK_RENDERED: null,
  REVIEW_TITLE_RENDERED: null,
  REVIEWER_INFO_RENDERED: null,
  REVIEWER_INITIAL_RENDERED: null,
  REVIEWER_PHOTO_RENDERED: null,
  RECOMMENDATION_RENDERED: null,
  RECOMMENDATION_ICON_RENDERED: null,
  RECOMMENDATION_TEXT_RENDERED: null,
  RECOMMENDATION_DESC_RENDERED: null,
  RATING_RENDERED: null,
  LEFT_ARROW_RENDERED: null,
  RIGHT_ARROW_RENDERED: null,
  UPGRADE_TIER_MSG: null,
  REVIEWS_LOADER: null
});
