import { each } from 'lodash';

export const getMappedManualReviews = (manualReviews = [], defaultUserName = 'Anonymous') => {
  const data = {
    totalReviews: manualReviews.length,
    reviews: []
  };
  if (manualReviews.length) {
    each(manualReviews, review => {
      data.reviews.push({
        imageData: review.photo || {},
        name: review.name || defaultUserName,
        date: review.date || '',
        rating: review.rating,
        body: review.body,
        link: review.link || null,
        metadata: {
          title: review.title
        }
      });
    });
  }

  return data;
};

export const formatDate = (dateStr, market) => {
  if (!dateStr || !Date.parse(dateStr)) return '';

  if (/^\d{4}-\d{2}-\d{2}/.test(dateStr)) {
    // For ISO 8601 strings, parse manually so browser timezone is used
    const dateArr = dateStr.split(/\D+/).map(s => parseInt(s, 10));
    dateArr[1] = dateArr[1] - 1; // adjust month
    return new Date(...dateArr).toLocaleDateString(market);
  }

  return new Date(dateStr).toLocaleDateString(market);
};
